@font-face {
    font-family: 'Helvetica';

    src: local('Helvetica'),
         url('/fonts/Helvetica.woff2') format('woff2'),
         url('/fonts/Helvetica.woff') format('woff');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';

    src: local('/fonts/Helvetica Bold'), local('Helvetica-Bold'),
         url('/fonts/Helvetica-Bold.woff2') format('woff2'),
         url('/fonts/Helvetica-Bold.woff') format('woff');

    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
