:root{
    --dark-shade-100: #F5F7FA;
    --dark-shade-200: #EAE7EB;
    --dark-shade-300: #CBD2D9;
    --dark-shade-400: #9AA5B1;
    --dark-shade-500: #7B8794;
    --dark-shade-600: #616E7C;
    --dark-shade-700: #52606D;
    --dark-shade-800: #3E4C59;
    --dark-shade-900: #323F4B;
    --dark-shade-1000: #1F2833;
    --dark-shade-1100: #323F4B;
    --dark-shade-1200: #1F2933;
    --dark-shade-1250: #162025;
    --dark-shade-1300: #12171C;

    --theme-white: rgb(255, 255, 255);
    --theme-white-opacity-95: rgba(255, 255, 255, 0.95);
    --theme-white-opacity-75: rgba(255, 255, 255, 0.75);
    --theme-white-opacity-70: rgba(255, 255, 255, 0.70);
    --theme-white-opacity-50: rgba(255, 255, 255, 0.50);
    --theme-white-opacity-40: rgba(255, 255, 255, 0.40);
    --theme-white-opacity-25: rgba(255, 255, 255, 0.25);
    --theme-white-opacity-20: rgba(255, 255, 255, 0.20);
    --theme-white-opacity-10: rgba(255, 255, 255, 0.10);

    --theme-black: rgb(0, 0, 0);
    --theme-black-opacity-75: rgba(0, 0, 0, 0.75);
    --theme-black-opacity-70: rgba(0, 0, 0, 0.70);
    --theme-black-opacity-65: rgba(0, 0, 0, 0.65);
    --theme-black-opacity-50: rgba(0, 0, 0, 0.50);
    --theme-black-opacity-40: rgba(0, 0, 0, 0.40);
    --theme-black-opacity-30: rgba(0, 0, 0, 0.30);
    --theme-black-opacity-25: rgba(0, 0, 0, 0.25);
    --theme-black-opacity-20: rgba(0, 0, 0, 0.20);
    --theme-black-opacity-10: rgba(0, 0, 0, 0.10);
    --theme-black-opacity-04: rgba(0, 0, 0, 0.04);

    --theme-primary-0: #4482FF;
    --theme-secondary-2: #788195;
    --theme-secondary-6: #f5f6f8;

    --theme-grayscale-0: rgb(186, 186, 186);
    --theme-grayscale-1: rgb(193, 193, 193);
    --theme-grayscale-2: rgb(216, 216, 216);
    --theme-grayscale-3: rgb(241, 241, 241);
    --theme-grayscale-6: rgb(249, 249, 249);
    --theme-grayscale-9: rgb(251, 251, 251);


    --theme-border-0: rgb(233, 233, 233);
    --theme-border-2: rgb(235, 235, 235);

    --theme-text-0: #323332;
    --theme-text-1: #595959;
    --theme-text-2: #979797;
    --theme-text-3: #797979;

    --theme-error-0: #f64744;

    --theme-red: #eb5b56;
    --theme-blue: #4490f7;


    --theme-light-grey: #fafafa;
    --theme-medium-grey: #bababa;
    --theme-dark-grey: #eaeaea;

    --theme-light-green: #649950;
    --theme-dark-green: #40822b;

    --theme-light-blue: #88b5e5;
    --theme-dark-blue: #4490f7;

    --theme-light-red: #e59d88;
    --theme-dark-red: #eb7035;

    --theme-border-radius: 8px;
    --theme-input-radius: 8px;
    --theme-button-radius: 8px;
    --theme-button-small-radius: 3px;
}


/*—— GENERAL ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-text-color: rgba(0, 0, 0, 0.85);
    --theme-text-color-full: rgb(0, 0, 0);
    --theme-text-color-faded: rgba(0, 0, 0, 0.50);

    --theme-soft-contract-background: #f1f3f6;
    --theme-soft-contract-border-color: #ebebeb;
    --theme-hight-contract-background: var(--theme-white);
    --theme-hight-contract-border-color: #e1e1e1;

    --theme-layout-background: #FFF;
    --theme-layout-content-background: 'blue';
    --theme-layout-content-border-color: #e9e9e9;

    --theme-signature-background: var(--theme-grayscale-6);

    --theme-loading-wrapper-background: #ffffff70;

    --theme-table-row-hightlight-background: #B4CBE0;

}

body.dark{
    --theme-text-color: rgba(255, 255, 255, 0.85);
    --theme-text-color-full: rgb(255, 255, 255);
    --theme-text-color-faded: rgba(255, 255, 255, 0.50);

    --theme-soft-contract-background: var(--dark-shade-900);
    --theme-soft-contract-border-color: var(--dark-shade-1100);
    --theme-hight-contract-background: var(--dark-shade-1000);
    --theme-hight-contract-border-color: var(--dark-shade-1100);

    --theme-layout-background: var(--dark-shade-1200);
    --theme-layout-content-background: var(--dark-shade-1250);
    --theme-layout-content-border-color: var(--dark-shade-900);

    --theme-signature-background: var(--dark-shade-900);

    --theme-loading-wrapper-background: #ffffff70;
}


/*—— ALERT ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-alert-group-border-color: #ecece1;
}

body.dark{
}


/*—— SIDE BAR ——————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-leftmenu-root-text-color: #666666;
    --theme-leftmenu-root-text-color-hover: #000000;

    --theme-leftbar-border-color: #cccccc;

    --theme-topbar-border-color: var(--dark-shade-200);

    --theme-menu-border: #cccccc;
}

body.dark{
    --theme-leftmenu-root-text-color: #F5F7FA;
    --theme-leftmenu-root-text-color-hover: #666666;

    --theme-leftbar-border-color: var(--dark-shade-1200);

    --theme-topbar-border-color: var(--dark-shade-1200);

    --theme-menu-border: var(--dark-shade-1200);
}


/*—— LANDING ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-landing-zone-background: var(--theme-white);
    --theme-landing-zone-nodata-color: #7d7d7d;
    --theme-landing-zone-border-color: #5590cc;
    --theme-agency-logo-color: #000;
}

body.dark{
    --theme-landing-zone-background: var(--dark-shade-800);
    --theme-landing-zone-nodata-color: var(--dark-shade-300);
    --theme-landing-zone-border-color: var(--dark-shade-600);
    --theme-agency-logo-color: #FFF;
}


/*—— HIGHTLIGHT ————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-highlight-background: #f1f2f5;
    --theme-highlight-shadow: var(--theme-black-opacity-50);
}

body.dark{
    --theme-highlight-background: rgba(255, 255, 255, 0.04);
    --theme-highlight-shadow: var(--dark-shade-600);
}


/*—— FORM ——————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-fieldset-legend-background: #fff;
    --theme-form-repeater-background: var(--theme-white);
}

body.dark{
    --theme-fieldset-legend-background: var(--dark-shade-1200);
    --theme-form-repeater-background: var(--dark-shade-900);
}


/*—— QUILL —————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-editor-toolbar-color: #000;
    --theme-editor-toolbar-background: #F1F1F1F1;
    --theme-editor-container-background: #FFF;
}

body.dark{
    --theme-editor-toolbar-color: #FFF;
    --theme-editor-toolbar-background: var(--dark-shade-900);
    --theme-editor-container-background: var(--dark-shade-1000);
}


/*—— BOOKING ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-booking-page-background: #e6e0dc;
    --theme-booking-week-background: #FFF;
    --theme-booking-border-color: #d9d0c9;
    --theme-booking-usercard-background: #FFF;
    --theme-booking-usercard-selected-background: rgb(204, 204, 204);
    --theme-booking-usercard-border-color: #e6e0dc;
    --theme-booking-group-background: #FFF;
    --theme-booking-group-selected-background: rgb(204, 204, 204);
    --theme-booking-group-text-color: currentColor;
    --theme-booking-header-background: #f1f2f3;
    --theme-booking-today-background: #00152920;
    --theme-booking-letter-background: #f6f6f7;
    --theme-booking-letter-background-active: #fcc97f;
    --theme-booking-letter-background-hover: #fee2ba;
    --theme-booking-drag-background: var(--theme-black-opacity-40);
    --theme-booking-models-default-background: #e1e1e1;
    --theme-booking-models-default-text-color: var(--theme-text-color);
    --theme-booking-models-accepted-background: #8bc34a;
    --theme-booking-models-accepted-text-color: var(--ant-color-text);
    --theme-booking-models-declined-background: #f6f6f6;
    --theme-booking-models-declined-text-color: var(--ant-color-text);
    --theme-booking-models-confirmed-background: #03a9f4;
    --theme-booking-models-confirmed-text-color: var(--theme-text-color);
    --theme-booking-loading-mask-background: var(--theme-white-opacity-75);

    --mail-editable-background: var(--theme-white);
    --mail-editable-border-color: var(--theme-black-opacity-10);
    --mail-editable-shadow-color: var(--theme-black-opacity-75);

    --mail-attachment-background: var(--theme-grayscale-9);;
    --mail-attachment-border-color: #d9d9d9;
}

body.dark{
    --theme-booking-page-background: var(--dark-shade-1100);
    --theme-booking-week-background: var(--dark-shade-800);
    --theme-booking-border-color: var(--dark-shade-400);
    --theme-booking-usercard-background: var(--dark-shade-600);
    --theme-booking-usercard-selected-background: var(--dark-shade-400);
    --theme-booking-usercard-border-color: var(--dark-shade-1200);
    --theme-booking-group-background: var(--dark-shade-600);
    --theme-booking-group-selected-background: var(--dark-shade-400);
    --theme-booking-group-text-color: var(--dark-shade-900);
    --theme-booking-header-background: var(--dark-shade-600);
    --theme-booking-today-background: var(--dark-shade-500);
    --theme-booking-letter-background: var(--dark-shade-700);
    --theme-booking-letter-background-active: var(--dark-shade-500);
    --theme-booking-letter-background-hover: var(--dark-shade-600);
    --theme-booking-drag-background: var(--theme-white-opacity-40); /*var(--dark-shade-800);*/
    --theme-booking-models-default-background: var(--dark-shade-500);
    --theme-booking-models-default-text-color: var(--theme-text-color);
    --theme-booking-models-accepted-background: #8bc34a;
    --theme-booking-models-accepted-text-color: var(--theme-text-color);
    --theme-booking-models-declined-background: var(--dark-shade-300);
    --theme-booking-models-declined-text-color: rgba(0, 0, 0, 0.85);
    --theme-booking-models-confirmed-background: #03a9f4;
    --theme-booking-models-confirmed-text-color: var(--theme-text-color);
    --theme-booking-loading-mask-background: var(--theme-black-opacity-75);

    --mail-editable-background: var(--theme-highlight-background);
    --mail-editable-border-color: var(--theme-white-opacity-20);
    --mail-editable-shadow-color: var(--theme-white-opacity-75);

    --mail-attachment-background: var(--dark-shade-900);
    --mail-attachment-border-color: var(--dark-shade-500);
}


/*—— PROFILE ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --profile-grid-media-background: var(--theme-dark-grey);
    --profile-grid-media-border-color: #d3d3d3;
    --profile-grid-media-action-background: var(--theme-dark-grey);

    --theme-svg-connector-path-stroke: #C1C1C1;

    --theme-profile-config-option-background: var(--theme-list-border-color);
    --theme-profile-config-active-background: var(--theme-list-border-color);
    --theme-profile-config-hover-background: var(--theme-list-border-color);
}

body.dark{
    --profile-grid-media-background: var(--dark-shade-900);
    --profile-grid-media-border-color: var(--dark-shade-800);
    --profile-grid-media-action-background: var(--dark-shade-900);

    --theme-svg-connector-path-stroke: var(--dark-shade-700);

    --theme-profile-config-option-background: var(--dark-shade-900);
    --theme-profile-config-active-background: var(--dark-shade-700);
    --theme-profile-config-hover-background: var(--dark-shade-800);
}


/*—— TOOLTIP ———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-tooltip-background: #e1f5fe;
    --theme-tooltip-border-color: #afc2cb;
    --theme-tooltip-text-color: #000;
    --theme-tooltip-header-text-color: #000;
    --theme-tooltip-footer-text-color: #000;
}

body.dark{
    --theme-tooltip-background: var(--dark-shade-600);
    --theme-tooltip-border-color: var(--dark-shade-900);
    --theme-tooltip-text-color: #FFF;
    --theme-tooltip-header-text-color: #FFF;
    --theme-tooltip-footer-text-color: #FFF;
}


/*—— AVATAR ————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-avatar-background: #e1e1e1;
    --theme-avatar-text-color: var(--theme-white);
}

body.dark{
    --theme-avatar-background: var(--dark-shade-800);
    --theme-avatar-text-color: var(--dark-shade-1000);
}


/*—— AGENDA ————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-agenda-page-background: #e5e0dc;
    --theme-agenda-week-background: #FFF;
    --theme-agenda-month-background: var(--theme-white);
    --theme-agenda-border-color: #d9d0c9;
    --theme-agenda-usercard-background: #FFF;
    --theme-agenda-usercard-selected-border-color: #607d8b;
    --theme-agenda-header-background: #f1f2f3;
    --theme-agenda-today-background: #00152920;
    --theme-agenda-overlay-background: #FFF;
}

body.dark{
    --theme-agenda-page-background: var(--dark-shade-1100);
    --theme-agenda-week-background: var(--dark-shade-900);
    --theme-agenda-month-background: var(--dark-shade-1000);
    --theme-agenda-border-color: var(--dark-shade-400);
    --theme-agenda-usercard-background: var(--dark-shade-600);
    --theme-agenda-usercard-selected-border-color: var(--dark-shade-400);
    --theme-agenda-header-background: var(--dark-shade-600);
    --theme-agenda-today-background: var(--dark-shade-500);
    --theme-agenda-overlay-background: var(--dark-shade-800);
}


/*—— BLOCK —————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-block-header-background: #d9d9d9;
    --theme-block-content-border-color: #d9d9d9;
    --theme-block-content-background: #FFF;
    --theme-block-content-background-faded: rgb(250, 250, 250);
}

body.dark{
    --theme-block-header-background: var(--dark-shade-1000);
    --theme-block-content-border-color: var(--dark-shade-1000);
    --theme-block-content-background: var(--dark-shade-1250);
    --theme-block-content-background-faded: var(--dark-shade-1100);
}


/*—— SETTINGS———————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-setting-agency-background: var(--theme-grayscale-3);
}

body.dark{
    --theme-setting-agency-background: var(--dark-shade-1100);
}


/*—— USER ——————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-divider-border-color: rgba(0, 0, 0, 0.06);
}

body.dark{
    --theme-divider-border-color: var(--dark-shade-500);
}

/*—— POPING ————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-popin-dark-background: #1d1d1f;
    --theme-popin-light-background: var(--theme-white);
}

body.dark{
    --theme-popin-dark-background: #1d1d1f;
    --theme-popin-light-background: var(--theme-layout-content-background);
}


/*—— CHAT ——————————————————————————————————————————————————————————————————————————————————————————————————————————— */
:root{
    --theme-chat-background: #FFF;
    --theme-chat-header-background: #f5f9fd;
    --theme-chat-footer-background: #f5f9fd;
    --theme-chat-footer-text-color: #565867;
    --theme-chat-my-msg-background: #e0f3fc;
    --theme-chat-you-msg-background: #f1f4f8;
    --theme-chat-thread-msg-size: 10px;
}

body.dark{
    --theme-chat-background: var(--dark-shade-700);
    --theme-chat-header-background: #293847;
    --theme-chat-footer-background: #293847;
    --theme-chat-footer-text-color: var(--dark-shade-100);
    --theme-chat-my-msg-background: var(--dark-shade-600);
    --theme-chat-you-msg-background: var(--dark-shade-900);
}




/*————————————————————————————————————————————————————————————————————————————————————————————————————————————————————*/

:root-off{

    --theme-accent-background: rgba(0, 0, 0, 0.2);



    /*--theme-heading-title-color: rgba(0, 0, 0, 0.85);*/

    /*--theme-btn-background: #FFF;
    --theme-btn-background-hover: #f5f6f8;
    --theme-btn-text-color: var(--theme-text-color);
    --theme-btn-border-color: #d9d9d9;
    --theme-btn-border-color-hover: #40a9ff;
    --theme-btn-disabled-background: #f5f5f5;
    --theme-btn-disabled-border-color: var(--theme-btn-border-color);
    --theme-btn-danger-ghost-background: #FFF;*/



    /*--theme-topbar-background: #ffffff;*/
    /*--theme-topbar-text-color: #000000;*/
    /*--theme-topbar-icon-color: #404040;*/

    /*--theme-leftbar-background: #ffffff;*/
    /*--theme-leftbar-border-color: #cccccc;*/



    --theme-menu-background: var(--theme-white);
    --theme-menu-active-text-color: var(--theme-black);
    --theme-menu-border: #cccccc;
    --theme-menu-arrow-color: var(--dark-shade-400);
    /*--theme-menu-sub-background: var(--dark-shade-300); !*#e6e8eb;*!*/



    /*--theme-input-background: #ffffff;
    --theme-input-background-disabled: #ffffff;
    --theme-input-text-color: #000000;
    --theme-input-text-color-disabled: rgba(0, 0, 0, 0.25);
    --theme-input-border-color: #d9d9d9;
    --theme-input-border-color-disabled: #d9d9d9;
    --theme-input-hover-border-color: #40a9ff;
    --theme-input-focus-shadow-color: rgb(24 144 255 / 20%);*/
    /*--theme-checkbox-border-color: #d9d9d9;*/
    /*--theme-select-icon-color: #00000040;
    --theme-select-icon-color-hover: rgba(255, 255, 255, 0.55);*/

    /*--theme-modal-background: #FFF;
    --theme-modal-border-color: #f0f0f0;
    --theme-modal-close-color: rgba(0, 0, 0, .45);
    --theme-modal-close-color-hover: #000;*/

    --theme-tag-background: rgb(234, 234, 234); /*// #f5f5f5;*/
    --theme-tag-border-color: #f0f0f0;

    /*--theme-table-background: #FFF;
    --theme-table-border-color: #F0F0F0;
    --theme-table-head-background: #FAFAFA;
    --theme-table-head-color: var(--theme-text-color);
    --theme-table-head-separator-color: rgba(0, 0, 0, 0.06);
    --theme-table-row-hover-background: #fafafa;*/

    --theme-tabs-border-color: #f0f0f0;

    /*--theme-select-dropdown-background: #FFF;
    --theme-select-dropdown-selected-background: #e6f7ff;
    --theme-select-dropdown-active-background: #f5f5f5;
    --theme-select-dropdown-separator: var(--theme-border-2);*/

    /*--theme-pagination-item-background: #FFF;
    --theme-pagination-item-border-color: #d9d9d9;
    --theme-pagination-item-color: var(--theme-text-color);
    --theme-pagination-item-color-disabled: var(--theme-text-color-faded);*/

    /*--theme-popover-background: #FFF;*/

    --theme-list-border-color: #f0f0f0;
    --theme-list-item-selected-background: #d9d9d9;

    --theme-editor-border-color: #e9e9e9;

}
