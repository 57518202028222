.isoLayoutContentWrapper h1 {
    font-size: 28px;
}

.isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
