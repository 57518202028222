/*-- Utility Classes --•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--•--••*/

.pointer{
    cursor: pointer;
}

.uppercase{
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.v-top {
    vertical-align: top;
}

.v-center {
    vertical-align: center;
}

.v-bottom {
    vertical-align: bottom;
}

/*——————————————————————*/

.m0 {
    margin: 0 !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb10 {
    margin-bottom: 10px;
}

/*——————————————————————*/

.m-05r {
  margin: .5rem !important;
}

.m-1r {
    margin: 1rem !important;
}

/*——————————————————————*/

.mt-05r {
      margin-top: .5rem !important;
  }

.mt-1r {
    margin-top: 1rem !important;
}

.mt-2r {
    margin-top: 2rem !important;
}

.mt-3r {
    margin-top: 3rem !important;
}

/*——————————————————————*/

.mb-1r {
      margin-bottom: 1rem !important;
  }

.mb-2r {
    margin-bottom: 2rem !important;
}

.mv-3r {
    margin-bottom: 3rem !important;
}
